.form-field__register{
    padding:  10px;
}

.form-field-button__register{
   
    margin : 20px 10px;
}
.form-error_register{
    color : rgb(238, 97, 97);
    font-size: 18px;
    font-family: Helvetica;
}
#registerationForm{
    text-align: center;
    display: inline-block;
    left: 1%;
    right: 1%;
}