.login-page{
    height: 80vh;
}
.login-page__button, .login-page__button:hover{
    padding: 0px 0.6vw;
    height: 3.4vw;
/* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    opacity: 1;
    text-align: center;
    font:  20px/24px Helvetica Neue;
    font-size: 1.1vw;
    letter-spacing: 0px;
    color: #333131;
    outline: none;
    border: none;
    font-family: Helvetica;
}

.login-page__logo{
    width: 20%;
    height: 6%;
}


@media only screen and (min-width: 600px) {
    .login-page__form{
        margin-top: 10vh;
    }
  }

  @media screen and (max-width: 700px) {
    .login-page__logo{
        width: 40%;
        height: 10%;
    }
    .login-page__button, .login-page__button:hover{
        padding: 0px 1.8vw;
        height: 10.3vw;
    /* UI Properties */
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 8px;
        opacity: 1;
        text-align: center;
        font:  20px/24px Helvetica Neue;
        font-size: 3.3vw;
        letter-spacing: 0px;
        color: #333131;
        outline: none;
        border: none;
        font-family: Helvetica;
    }
  }