.report-form-shadow-filter-box{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 2px;
    opacity: 1;
    margin: 21px auto;
    padding: 12px;
    
}

.report-form-heading{
    text-align: left;
    font: normal normal medium 20px/25px Helvetica Neue;
    font-weight: 600;
    letter-spacing: 0px;
    color: #000000;
    padding: 10px 0px 0 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    font-family: Helvetica;
}

.report-form-recipients{
    text-align: left;
    font: normal normal medium 20px/25px Helvetica Neue;
    font-weight: 600;
    letter-spacing: 0px;
    color: #000000;
    font-family: Helvetica;
}

.report-form-input {
    align-self: left;
    display: inline-block;
    padding: 6px;
    border: 1px solid #C6C6C6;
border-radius: 2px;
opacity: 1;
width: 100%;
margin: 2px 0;
  }

  .report-form-input-1 {
    align-self: left;
    display: inline-block;
    padding: 6px;
    border: 1px solid #C6C6C6;
border-radius: 2px;
opacity: 1;
width: 85%;
margin: 2px 0;
  }
  .report-form-input-2 {
    align-self: left;
    display: inline-block;
    padding: 6px;
    border: 1px solid #C6C6C6;
border-radius: 2px;
opacity: 1;
// width: 94%;
margin: 2px 0;
  }

  .report-form-input-box{
    outline: none;
    border: none;
  }

  .schedule-send-button{
      padding: 0.4vw ;
      background: #2472ED 0% 0% no-repeat padding-box;
border-radius: 2px;
margin: 3px 5px;
height: 40px;
color: #FFFFFF;
  }