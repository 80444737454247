.form-field__login{  
    height: 4vw;
    padding: 0.4vw;
    margin: 1.1vw 0px !important ;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    border: none;
}
.form-field__login:focus{
    outline: none;
    box-shadow: 2px 5px 18px #00000029;
}
.form-field-button__login{
    height: 50px;
    /* UI Properties */
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    font:  20px/24px Helvetica Neue;
    font-size: 1.1vw;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-family: Helvetica;
}
.form-error_login{
    color : rgb(238, 97, 97);
    font-size: 1vw;

}

.form-field__login::placeholder{
    font: 20px/24px Helvetica Neue;
    font-size: 1.1vw;
    font-family: Helvetica;
    letter-spacing: 0px;
    color: #7C7C7C;
    opacity: 1;
}

@media screen and (max-width: 700px) {
    .form-field__login{  
        height: 12vw;
        padding: 1.2vw;
        margin: 3.3vw 0px !important ;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        opacity: 1;
        border: none;
    }
    .form-field__login:focus{
        outline: none;
        box-shadow: 2px 5px 18px #00000029;
    }
    .form-field-button__login{
        height: 40px;
        /* UI Properties */
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        font: 20px/24px Helvetica Neue;
        font-size: 2.5vw;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        font-family: Helvetica;
    }
    .form-error_login{
        color : rgb(238, 97, 97);
        font-size:3vw;
    
    }
    .form-field__login::placeholder{
        font: 20px/24px Helvetica Neue;
        font-size: 3.3vw;
        letter-spacing: 0px;
        color: #7C7C7C;
        opacity: 1;
        font-family: Helvetica;
    }
    
  }