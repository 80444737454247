//core files
@import "bootstrap/dist/css/bootstrap.min.css";

//pages
@import "./pages/home.scss";
@import "./pages/login.scss";
@import "./pages/report.scss";
@import "./pages/account.scss";
@import "./pages/users.scss";
@import "./components/editReport.scss";

//components
@import "./components/heatmap.scss";
@import "./components/forms/forms.scss";
@import "./components/sidebar.scss";
@import "./components/reportDashboard.scss";
@import "./components/usersTable.scss";

body {
    text-align: center;
    font-size: 0.9vw;
    font:  Helvetica Neue;
    font-family: Helvetica;
    min-height: 500px;
    min-width: 800px;
    overflow-y: auto;
    overflow-x: auto;
    font-family: Helvetica;
}

.glass_container {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur();
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    padding: 20px 20px;
    height: 88vh;
    overflow-y: scroll;
  }

  .glass_container_1 {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur();
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    padding: 20px 20px;
    height: auto;
    overflow-y: auto;
  }

  .glass_container_2 {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur();
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    padding: 20px 20px;
    height: 93vh;
  }