table{
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
}

td { 
    max-height : 10px;
    width : 50px;
    border-width:  2px;
    border-color: #FFFFFF;
    text-align: center;
    color: #2e2d2d;
    padding: 4px 3px 4px 3px;
}

#heatmap{ 
    text-align: center;
    display: inline-block;
    left: 1%;
    right: 1%;
    top: 50%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 2px;
    opacity: 1;
    margin: 12px 5px 21px 5px;
    padding: 12px;
}

#pdfprint1{
    margin-left: 10%;
    width: 90%;
}