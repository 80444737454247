.pdf-print{
    padding: 30px;
    min-height: 100vh;
    width: 100vw;
    background-color: #fff;
    position: absolute;
    z-index: 99;
}
.pdf-print-1{
    min-height: 100vh;
    width: 100vw;
    background-color: #fff;
    position: absolute;
    z-index: 99;
}
.square-loader{
    z-index: 100;
position: absolute;
margin-left: 0px;
}
.heatmap_table{
    border: 1px solid rgba($color: #000000, $alpha: 0.2); 
    box-shadow: none;
}
.heatmap_width{
    width: 60%;
    margin-left: 20%;
}
.form-field__heatmap{
    padding:  5px;
    margin : 20px 10px;
    width: 130px
}

.loader-animate{
    margin-left: 45%;
}

.form-field-button__heatmap{
    width : 200px;
    margin : 20px 10px;
}

#pdf_area{
    margin: 0 60px;
}

.form-field-shadow-filter-big-box{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 2px;
    opacity: 1;
    margin: 21px 33px;
    padding:  10px;
    max-height: 96vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.form-field-shadow-filter-big-box-1{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 2px;
    opacity: 1;
    margin: 21px 5px 0 9px;
    padding:  10px;
}
.form-field-shadow-filter-big-box-2{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 2px;
    opacity: 1;
    margin: 21px 5px 0 9px;
    padding:  10px;
}
.form-field-shadow-filter-box{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 2px;
    opacity: 1;
    margin: 21px 5px;
    padding: 0.8vw;
}
.form-field-shadow-filter-box-time{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 2px;
    opacity: 1;
    margin: 21px 5px;
}

.form-field-time-filter-button-blue{
    font: normal normal 1vw Helvetica Neue;
    font-weight: 600;
    letter-spacing: 0px;
    color: #000000;
    border-bottom: 7px solid #2472ED;
    padding: 5px;
    margin:  2% 2% 0 2%;
    width: 21%;
    display: inline-block;
    cursor: pointer;
    font-family: Helvetica;
}

.form-field-time-filter-button-gray{
    font: normal normal  0.9vw Helvetica Neue;
    letter-spacing: 0px;
    color: #000000;
    border-bottom: 7px solid #F2F2F2;
    margin:  2% 2% 0 2%;
    width: 21%;
    padding: 5px;
    display: inline-block;
    cursor: pointer;
    font-family: Helvetica;
}

// date input

.form-field-date-input{
    font: normal normal normal 0.8vw Helvetica Neue;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    display: inline-block;
    font-family: Helvetica;
}

.form-field-date-submit-button{
    display: inline-block;
    min-width: 30px;
    margin: 0% 1%;
}

.accordian-heading{
    text-align: left;
    text-align: left;
    font: normal normal medium 20px/25px Helvetica Neue;
    font-size: 0.95vw;
    font-weight: 600;
    letter-spacing: 0px;
    color: #000000;
    padding: 10px 0px 0 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    font-family: Helvetica;
}

.accordian-arrow{
    position: absolute;
    right: -10px;
    font-size: 0.9vw;
    opacity: 0.7;
}

.vertical-arrow{
    font-size: 0.9vw;
    opacity: 0.7;
}

.accordian-data-space{
    margin-top: 1vw;
    height: 10vw;
    overflow-y: scroll;
}
//geofence

.form-field-geofence-selct-all-button{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 2px;
    font-weight: 300;
    font-size: 0.8vw;
    opacity: 1;
    align-self: right;
    text-align: center;
    display: inline-flex;
    padding: 5px;
    margin: 0px 15px;
    font-family: Helvetica;
}   

.search-input{ 
    border: 0px;
    width: 85%;
}

.search-input-box{ 
    border: 1px solid #DBDBDB;
    border-radius: 4px;
    opacity: 1;
    padding: 3px;
    display: inline-block;

}

.form-field-radio-input{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 2px;
    opacity: 1;
    margin: 5px;
    height: 2.1vw;
}

.rectangle-blue{
    min-height: 2.1vw;
    min-width: 2.1vw;
    background-color: #2472ED;
    border-radius: 5px 0 0 5px;
}

.rectangle-gray{
    min-height: 2.1vw;
    min-width: 2.1vw;
    background-color: #F2F2F2;
    border-radius: 5px 0 0 5px;

}
.heatamp-button__report{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 2px;
    opacity: 1;
    cursor: pointer;
}

@media screen and (max-width: 700px) {
    .form-field-time-filter-button-blue{
        font: normal normal 3vw Helvetica Neue;
        font-family: Helvetica;
    }
    .form-field-time-filter-button-gray{
    font: normal normal  2.7vw Helvetica Neue;
    font-family: Helvetica;
    }
    .form-field-date-input{
        font: normal normal normal 2.4vw Helvetica Neue;
        font-family: Helvetica;
    }
    .accordian-heading{
        font-size: 3vw;
    }
}