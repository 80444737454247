.users-page-title{
    font: normal normal medium 34px Helvetica Neue;
    font-size: 2rem;
    font-weight: 500;
letter-spacing: 0px;
color: #000000;
opacity: 1;
text-align: left;
margin-left: 5%;
display: inline-block;
}

.users-create-new-button{ 
padding: 10px;
font: normal normal medium 20px/25px Helvetica Neue;
font-size: 1.3rem ;
letter-spacing: 0px;
color: #000000;
opacity: 1;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border-radius: 10px;
opacity: 1;
display: inline-block;
}

.users-dashboard-outer-box{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 2px;
  opacity: 1;
  display: inline-block;
  padding: 6px;
  margin: 40px 5%;
  width: 90%;
  
  // height: 90vh;
}  

.users-dashboard-blue-outer-box{
  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.users-dashboard-blue-box{
  padding: 1.1vw;
  background: #F6F9FE 0% 0% no-repeat padding-box;
  border-radius: 2px;
  opacity: 1;
}


  .container {
    text-align: center;
    }
    .toggle-switch {
    position: relative;
    width: 100px;
    display: inline-block;
    text-align: left;
    top: 8px;
    }
    .checkbox {
    display: none;
    }
    .label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    }
    .inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    }
    .inner:before,
    .inner:after {
    float: left;
    width: 50%;
    height: 36px;
    padding: 0;
    line-height: 36px;
    color: #fff;
    font-weight: bold;
    box-sizing: border-box;
    }
    .inner:before {
    content: "Admin";
    padding-left: 10px;
    background-color: rgb(0, 132, 255);
    color: #fff;
    }
    .inner:after {
    content: "User";
    padding-right: 10px;
    background-color: #bbb;
    color: #fff;
    text-align: right;
    }
    .switch {
    display: block;
    width: 24px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 60px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
    }
    .checkbox:checked + .label .inner {
    margin-left: 0;
    }
    .checkbox:checked + .label .switch {
    right: 0px;
    }
    

