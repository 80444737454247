.report-header{
    display: flex;
    flex-direction: row;  
    //height:5vh;
    margin-left: 1rem;
    align-items: center;
    // margin-top:2rem; 
    width:40vw;
}
.top-bar{
    display: flex;
    flex-direction: row;  
    align-items: center; 
    margin-top:2rem; 
    //justify-content: space-between; 
    width:74vw; 
}
.edit-div{
    width:38vw;
    //background-color: blue; 
    display: flex; 
    justify-content: flex-end;
     
    
}
.edit{
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;
    padding:0.5rem 1rem;
    border-radius: 5px;
}

.report-header-text{
    font-family: Helvetica;
    font-size: medium;
}

.main{
    display: flex;
    flex-direction: row; 
    width:80vw;  
    margin-top: 2rem; 
}
.box{
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 2px;
    opacity: 1;
    width:35vw;
    margin-left: 1rem; 
    padding-top:1rem;
    padding-bottom: 1rem;
    margin-top:1rem;
    display: flex;
    flex-direction: row;
    padding-left: 2rem;
    padding-right:2rem; 
}
.report-left-part{ 
    width:38vw;
}
.report-right-part{ 
    width:38vw;
}

.bold-text{
    font-weight: bold;
    font-family: Helvetica; 
    width:12vw;
    display: flex;
    justify-content: flex-start; 
    //margin-right: 4rem; 
}
.scroll-div{
    max-height: 20vh;
    flex:1;
    overflow-y: scroll;  
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
}
.box2{
    flex-direction: column;
}
.recipients{
    display: flex;
    width: 100%; 
    flex-direction: row;
    justify-content: space-between; 
     //padding-left:1rem;
     //padding-right:1rem;
    padding:0.5rem 1rem;
    align-items: center;
    border-radius: 5px;
    
}

.schedule{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.link-unstyled{
    text-decoration: none;
}