.account-form-wrapper{
    width: 85%;
}

.account-form-shadow-filter-box{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 2px;
    opacity: 1;
    margin: 21px 15px;
    position: relative;
}

.account-edit-button{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 2px;
  opacity: 1;
  margin:  10px;
  position: absolute;
  right: 0;
  cursor: pointer;
}

.account-form-input {
    align-self: left;
    display: inline-block;
    padding: 6px;
    font-size: 1vw;
    border: 1px solid #C6C6C6;
border-radius: 2px;
opacity: 1;
margin: 17px;
justify-content: left;
align-items: center;
  }

  .account-form-input-box{
    outline: none;
    border: none;
    flex: 1;
  }

  .account-form-label{
    font: normal normal medium 18px/23px Helvetica Neue;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 500;
    font-size: 0.9vw;
    margin: 0 10px;
    font-family: Helvetica;
  }

  .account-nav-button-blue{
    font: normal normal 1.1em Helvetica Neue;
    font-weight: 600;
    letter-spacing: 0px;
    color: #000000;
    border-bottom: 7px solid #2472ED;
    padding: 5px;
    margin:  2% 2% 0 2%;
    width: 21%;
    display: inline-block;
    cursor: pointer;
    font-family: Helvetica;
}

.account-nav-button-gray{
    font: normal normal  1em Helvetica Neue;
    letter-spacing: 0px;
    color: #000000;
    border-bottom: 7px solid #F2F2F2;
    margin:  2% 2% 0 2%;
    width: 21%;
    padding: 5px;
    display: inline-block;
    cursor: pointer;
    font-family: Helvetica;
}
.account-tnc-outer-shadow-filter-box{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 2px;
  opacity: 1;
  margin: 21px 15px;
  //position: relative;
  //padding: 20px 30px;
  height: 80vh;
  overflow-y: auto;
  background-color: #fff;
}

.account-tnc-outer-shadow-filter-box-1{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  //box-shadow: 0px 3px 6px #00000029;
  border-radius: 2px;
  opacity: 1;
  margin: 21px 25px;
  position: relative;
  //padding: 20px 30px;
  height: 80vh;
  overflow-y: auto;
}

.account-tnc-shadow-filter-box{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 2px;
  opacity: 1;
  margin: 21px 15px;
  position: relative;
  background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
border-radius: 3px;
padding: 31px;
}

.account-button__report{
  background: #FFFFFF 0% 0% no-repeat padding-box;
 // box-shadow: 0px 3px 6px #00000029;
 border-radius: 2px;
  opacity: 1;
  max-height: 50px;
  max-width: 50px;
  margin-top:20px
}
