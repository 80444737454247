.report-dashboard-outer-box{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 2px;
    opacity: 1;
    display: inline-block;
    padding: 6px;
    margin: 40px 5%;
    width: 90%;
}  

.report-dashboard-title{
    font: normal normal bold 16px/19px Helvetica Neue;
    font-family: Helvetica Neue;
    font-size: 0.9vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    text-align: left;
    font-family: Helvetica;

}

.report-dashboard-title-box{
    padding: 1.1vw;
}

.report-dashboard-content{
    font: normal normal normal 16px/18px Helvetica Neue;
    font-size: 0.9vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    text-align: left;
    font-family: Helvetica;
}

.report-dashboard-blue-box{
    padding: 1.1vw;
    background: #F6F9FE;
    border-radius: 2px;
    opacity: 1;
}

@media screen and (max-width: 700px) {
    .report-dashboard-title{
        font: normal normal bold  Helvetica Neue;
        font-family: Helvetica;
        font-size: 2.7vw;
    } 
    .report-dashboard-content{
        font: normal normal normal Helvetica Neue;
        font-family: Helvetica;
        font-size: 2.7vw;
    }
    
}