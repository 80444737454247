.sidebar__logo{
    width: 12.6vw;
    height: 3.9vw;
}
.navbar{
    align-items: normal !important;
    width: 17vw;
    height: 100vh;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    padding: 40px;
     .container{
        justify-content: flex-start !important; 
     }

}

.sidebar-container{
    height: 100vh;
    // overflow-y: scroll;
}

 .sidebar-button.active{
    line-height: 49px !important;
    text-align: center;
    border-radius: 2px;
    width: 10vw;
    height: 50px;
    font-weight: 500;
    font:  20px/24px Helvetica Neue;
    font-size: 1.1vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-family: Helvetica;
    text-decoration: none;
    color: black;
}

.side-button{
    line-height: 49px !important;
    text-align: left;
    margin-bottom: 10px;
    width: 10vw;
    height: 55px;
    font-weight: 500;
    font:  20px/24px Helvetica Neue;
    font-size: 1.1vw;
    font-family: Helvetica;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    text-decoration: none;
    color: black;
}

.sidebar-button-active{
    box-shadow: 0px 3px 6px #00000029;
}

.sidebar-button__nav{
    line-height: 3.4vw !important;
    text-align: center;
    font-weight: 500;
    font:  20px/24px Helvetica Neue;
    font-size: 1.1vw;
    letter-spacing: 0px;
    color: #000000 !important;
    font-family: Helvetica;
    //width: 100%; 
}
.sidebar-button__logout{
    // position: absolute;
    bottom: 3vw;
    //left: 25px;
}

.sidebar-button__account{
    // position: absolute;
    bottom: 8vw;
    //left: 25px;
}

.sidebar-button__users{
    // position: absolute;
    bottom: 13vw;
    //left: 25px;
}

@media screen and (max-width: 700px){
    .sidebar__logo{
        width: 37.5vw;
        height: 11.7vw;
    }
    .navbar{
        width: 23vw;
    }
    .sidebar-button.active{
    line-height: 13.5vw !important;
    //width: 20vw;
    height: 20px;
    font-size: 1.5vw;
    }
    .sidebar-button__nav{
    line-height: 13.5vw !important;
    font-size: 1.8vw;
    }
}