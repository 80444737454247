.report-page-title{
    font: normal normal medium 2vw Helvetica Neue;
    font-family: Helvetica ;
    font-size: 2vw;
    font-weight: 500;
letter-spacing: 0px;
color: #000000;
opacity: 1;
text-align: left;
margin-left: 5%;
display: inline-block;
margin-top: 2vw;
}

.report-create-new-button{ 
    padding: 0.8vw;
    font: normal normal medium 20px/25px Helvetica Neue;
    font-size: 1.1vw ;
    letter-spacing: 0px;
    background: #FFFFFF;
    color: black;
    text-decoration: none;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    margin: 2vw 5vw 0 0 ;
    width: 15vw;
    cursor: pointer;
    font-family: Helvetica;
}

@media screen and (max-width: 700px){
    .report-page-title{
    font-size: 6vw;
    margin-top: 6vw;
    }
}